import React from 'react'
import './WiC.css'

const WiC = () => {
    return (
        <div className='WiC'>
            <div className="container">
                <div className="content">
                    <h2>Website in costruction</h2>
                </div>
            </div>
        </div>
    )
}

export default WiC
